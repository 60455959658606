<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? (Sda__Contract__version != null ? [Sda__Contract__title, Sda__Contract__revision_no , Sda__Contract__partial_no].filter(Boolean).join('-') : Sda__Contract__title) : ''" help-page-component="AddSda"></appic-page-title-bar>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <SdaAlert
                        :alert="$t('message.closeAlert')"
                        mode="add"
                        type="warning"
                        class="mt-3"
                        v-if="addMode && newSdaUnsaved"
                />
                <SdaAlert
                        :alert="$t('message.closeAlert')"
                        mode="update"
                        type="warning"
                        class="mt-3"
                        v-if="updateMode && updatedSdaUnsaved"
                />
                <app-card :fullBlock="true" colClasses="xl12 lg12 md12 sm12 xs12">
                    <v-form lazy-validation ref="sdaForm" v-model="validForm">
                        <v-overlay
                            :value="loader"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                            color="primary"
                                            indeterminate
                                            size="40"
                                            width="6"
                                    />
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels accordion v-model="panel" multiple>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-6 v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadActiveSda') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1 class="align-center">
                                            <v-autocomplete
                                                    :items="allActiveSdas"
                                                    :loading="loadingAnotherSda"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Sda.id"
                                                    item-text="Sda.title"
                                                    required
                                                    solo
                                                    @change="loadAnotherActiveSda($event)"
                                            >
                                                <template v-slot:item="{item}">
                                                    <div class="d-flex flex-column py-1">
                                                        <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                        <span class="font-xs"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                        <span class="font-xs"> {{ $t('message.supplier') + ': ' + (item.Supplier.code ? item.Supplier.code : item.Supplier.otsname) }} </span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row pt-3 v-if="addMode === true">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.relatedContract') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                    :items="allActiveContracts"
                                                    :placeholder="$t('message.startTypingToSearch')"
                                                    :value="Sda__contract_id"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Contract.id"
                                                    item-text="Contract.title"
                                                    required
                                                    return-object
                                                    solo
                                                    @change="loadContract($event)"
                                            >
                                                <template v-slot:item="{item}">
                                                    <div class="d-flex flex-column py-1">
                                                        <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                        <span class="font-sm"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                        <span class="font-sm"> {{ $t('message.supplier') + ': ' + item.Supplier.title }} </span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{item}">
                                                    <span>{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.siReference') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>{{ Sda__title != null ? "APP SI " + Sda__title : "" }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.date') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-menu
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    max-width="290px"
                                                    min-width="290px"
                                                    ref="sdaDateMenu"
                                                    transition="scale-transition"
                                                    v-model="sdaDateMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            :label="Sda__sdadate ? '' : $t('message.required')"
                                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                            :value="computedSdaDateFormatted"
                                                            append-icon="event"
                                                            clearable
                                                            class="force-text-left"
                                                            hint="DD/MM/YY format"
                                                            hide-details="auto"
                                                            background-color="white"
                                                            persistent-hint
                                                            readonly
                                                            solo
                                                            v-on="on"
                                                            @click:clear="Sda__sdadate = null"
                                                            @click:append="sdaDateMenu = true"
                                                    />
                                                </template>
                                                <v-date-picker
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                        v-model="Sda__sdadate"
                                                        @input="sdaDateMenu = false"
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.shipper') }}</v-flex>
                                        <v-flex xs6 lg5 md7 py-1>
                                            <div :class="Sda__othershipper2 == 'oth' ? 'text-area-border' : ''">
                                                <v-select
                                                        :items="Sda__Addresses__Supplier"
                                                        :label="Sda__othershipper2 ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Sda__othershipper2"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column" v-html="item.text"></div>
                                                    </template>
                                                    <template v-slot:selection="{item}">
                                                        <span>{{ item.textSimple }}</span>
                                                    </template>
                                                </v-select>
                                                <div class="d-flex flex-column" v-if="Sda__othershipper2 == 'oth'">
                                                    <span class="text-center"><v-icon small>mdi-arrow-down</v-icon></span>
                                                    <v-textarea
                                                            :rules="[...validationRules.paragraph]"
                                                            :value="Sda__othershipperstatement"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            rows="3"
                                                            solo
                                                            @change="Sda__othershipperstatement = $event"
                                                    />
                                                </div>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.invoiceTo') }}</v-flex>
                                        <v-flex xs6 lg5 md7 py-1>
                                            <div :class="Sda__invoicingaddress2 == 'oth' ? 'text-area-border' : ''">
                                                <v-select
                                                        :items="Sda__Addresses__Customer"
                                                        :label="Sda__invoicingaddress2 ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Sda__invoicingaddress2"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-0 my-0" v-html="item.text"></div>
                                                    </template>
                                                    <template v-slot:selection="{item}">
                                                        <span>{{ item.textSimple }}</span>
                                                    </template>
                                                </v-select>
                                                <div class="d-flex flex-column" v-if="Sda__invoicingaddress2 == 'oth'">
                                                    <span class="text-center"><v-icon small>mdi-arrow-down</v-icon></span>
                                                    <v-textarea
                                                            :rules="[...validationRules.paragraph]"
                                                            :value="Sda__otherinvstatement"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            rows="3"
                                                            solo
                                                            @change="Sda__otherinvstatement = $event"
                                                    />
                                                </div>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ this.Sda__Contract__incoterm_id == 5 ? $t('message.consigneeAWB') : $t('message.consigneeBL') }}</v-flex>
                                        <v-flex xs6 lg5 md7 py-1>
                                            <div :class="Sda__consigneebltoinvaddress2 == 'oth' ? 'text-area-border' : ''">
                                                <v-select
                                                        :items="Sda__Addresses__Customer"
                                                        :label="Sda__consigneebltoinvaddress2 ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Sda__consigneebltoinvaddress2"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-0 my-0" v-html="item.text"></div>
                                                    </template>
                                                    <template v-slot:selection="{item}">
                                                        <span>{{ item.textSimple }}</span>
                                                    </template>
                                                </v-select>
                                                <div class="d-flex flex-column" v-if="Sda__consigneebltoinvaddress2 == 'oth'">
                                                    <span class="text-center"><v-icon small>mdi-arrow-down</v-icon></span>
                                                    <v-textarea
                                                            :rules="[...validationRules.paragraph]"
                                                            :value="Sda__otherconsignee"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            rows="3"
                                                            solo
                                                            @change="Sda__otherconsignee = $event"
                                                    />
                                                </div>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ this.Sda__Contract__incoterm_id == 5 ? $t('message.notifyPartyAWB') : $t('message.notifyPartyBL') }}</v-flex>
                                        <v-flex xs6 lg5 md7 py-1>
                                            <div :class="Sda__othernotifybl2 == 'oth' ? 'text-area-border' : ''">
                                                <v-select
                                                        :items="Sda__Addresses__Customer"
                                                        :label="Sda__othernotifybl2 ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required]"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Sda__othernotifybl2"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-0 my-0" v-html="item.text"></div>
                                                    </template>
                                                    <template v-slot:selection="{item}">
                                                        <span>{{ item.textSimple }}</span>
                                                    </template>
                                                </v-select>
                                                <div class="d-flex flex-column" v-if="Sda__othernotifybl2 == 'oth'">
                                                    <span class="text-center"><v-icon small>mdi-arrow-down</v-icon></span>
                                                    <v-textarea
                                                            :rules="[...validationRules.paragraph]"
                                                            :value="Sda__othernotifyblstatement"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            rows="3"
                                                            solo
                                                            @change="Sda__othernotifyblstatement = $event"
                                                    />
                                                </div>
                                                <div :class="Sda__alsonotifybl2 == 'oth' ? 'text-area-border mt-3' : ''">
                                                    <v-checkbox
                                                            :label="$t('message.showAlsoNotifyPartyBl')"
                                                            :class="Sda__alsonotifybl2 == 'oth' ? 'mt-0 mb-1' : 'mt-1 mb-1'"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            solo
                                                            :true-value="1"
                                                            v-model="Sda__alsonotifybl"
                                                    />
                                                    <v-select
                                                        :items="Sda__Addresses__Customer"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-if="Sda__alsonotifybl == 1"
                                                        v-model="Sda__alsonotifybl2"
                                                    >
                                                        <template v-slot:item="{item}">
                                                            <div class="d-flex flex-column py-0 my-0" v-html="item.text"></div>
                                                        </template>
                                                        <template v-slot:selection="{item}">
                                                            <span>{{ item.textSimple }}</span>
                                                        </template>
                                                    </v-select>
                                                    <div class="d-flex flex-column" v-if="Sda__alsonotifybl2 == 'oth'">
                                                        <span class="text-center"><v-icon small>mdi-arrow-down</v-icon></span>
                                                        <v-textarea
                                                                :rules="[...validationRules.paragraph]"
                                                                :value="Sda__alsonotifyblstatement"
                                                                clearable
                                                                dense
                                                                hide-details
                                                                rows="3"
                                                                solo
                                                                @change="Sda__alsonotifyblstatement = $event"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.consigneeCO') }}</v-flex>
                                        <v-flex xs6 lg5 md7 py-1>
                                            <div :class="Sda__consigneecert2 == 'oth' ? 'text-area-border' : ''">
                                                <v-select
                                                        :items="Sda__Addresses__Customer"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Sda__consigneecert2"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-0 my-0" v-html="item.text"></div>
                                                    </template>
                                                    <template v-slot:selection="{item}">
                                                        <span>{{ item.textSimple }}</span>
                                                    </template>
                                                </v-select>
                                                <div class="d-flex flex-column" v-if="Sda__consigneecert2 == 'oth'">
                                                    <span class="text-center"><v-icon small>mdi-arrow-down</v-icon></span>
                                                    <v-textarea
                                                            :rules="[...validationRules.paragraph]"
                                                            :value="Sda__othercertstatement"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            rows="3"
                                                            solo
                                                            @change="Sda__othercertstatement = $event"
                                                    />
                                                </div>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.consigneePC') }}</v-flex>
                                        <v-flex xs6 lg5 md7 py-1>
                                            <div :class="Sda__consigneephyto2 == 'oth' ? 'text-area-border' : ''">
                                                <v-select
                                                        :items="Sda__Addresses__Customer"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="Sda__consigneephyto2"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-0 my-0" v-html="item.text"></div>
                                                    </template>
                                                    <template v-slot:selection="{item}">
                                                        <span>{{ item.textSimple }}</span>
                                                    </template>
                                                </v-select>
                                                <div class="d-flex flex-column" v-if="Sda__consigneephyto2 == 'oth'">
                                                    <span class="text-center"><v-icon small>mdi-arrow-down</v-icon></span>
                                                    <v-textarea
                                                            :rules="[...validationRules.paragraph]"
                                                            :value="Sda__otherphytostatement"
                                                            clearable
                                                            dense
                                                            hide-details="auto"
                                                            rows="3"
                                                            solo
                                                            @change="Sda__otherphytostatement = $event"
                                                    />
                                                </div>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.documents') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.documentDeliveryAddress') }}</v-flex>
                                        <v-flex xs6 lg5 md7 py-1>
                                            <v-select
                                                    :items="defaultDeliveryAddresses"
                                                    :loading="loading.deliveryAddresses"
                                                    :placeholder="$t('message.seePresentationOfDocuments')"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Deliveryaddress.id"
                                                    item-text="Deliveryaddress.address"
                                                    solo
                                                    v-model="Sda__deliveryaddress_id"
                                            >
                                                <template v-slot:item="{item}">
                                                    <div class="d-flex flex-column py-1 my-0">
                                                        <span class="font-weight-bold">{{ item.Deliveryaddress.title }}</span>
                                                        <span class="font-xs" v-html="nl2br(item.Deliveryaddress.address)"></span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{item}">
                                                    <div class="d-flex flex-column py-1 my-0">
                                                        <span class="font-weight-bold">{{ item.Deliveryaddress.title }}</span>
                                                        <span class="font-xs" v-html="nl2br(item.Deliveryaddress.address)"></span>
                                                    </div>
                                                </template>
                                            </v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.noOfDocumentsRequired') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-radio-group
                                                    class="mt-1"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    row
                                                    v-model="Sda__docrequired"
                                            >
                                                <v-radio :label="$t('message.duplicate')" value="duplicate"/>
                                                <v-radio :label="$t('message.triplicate')" value="triplicate"/>
                                            </v-radio-group>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs12 lg12 md12 pl-5 py-1>
                                            <SdaClauseList :update-mode="updateMode"/>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.printPreferences') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.printTwoPages') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <v-checkbox
                                                    :true-value="1"
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    v-model="Sda__longform"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name === 'add_sda')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card elevation="0"class="pt-3">
                                        <v-tabs background-color="grey lighten-3">
                                            <v-tab key="email" href="#tab-email" @click="updateHistoryKey()"><v-icon small class="pr-3">send</v-icon>{{ $t('message.notifications') }}</v-tab>
                                            <v-tab key="audit_log" href="#tab-audit_log" @click="updateHistoryKey()"><v-icon small class="pr-3">list</v-icon>{{ $t('message.auditLog') }}</v-tab>
                                            <v-tab-item key="email" value="tab-email">
                                                <NotificationsLog asset="sdas" :asset_id="Sda__id" :key="'nl' + historyKey"/>
                                            </v-tab-item>
                                            <v-tab-item key="audit_log" value="tab-audit_log">
                                                <DocumentHistory asset="sdas" :asset_id="Sda__id" :key="'dh' + historyKey"/>
                                            </v-tab-item>
                                        </v-tabs>
                                    </v-card>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row>
                            <v-flex d-flex xs12>
                                <v-layout row px-3 pt-3 mb-3>
                                    <PrintShippingInstructionButton
                                            :shipping-instruction-id="Sda__id"
                                            :shipping-instruction-number="Sda__title"
                                            :update-mode="updateMode"
                                            :list-mode="false"
                                    />
                                    <v-btn
                                            :loading="loading.reset"
                                            class="ml-3"
                                            color="default"
                                            small
                                            v-if="addMode == true"
                                            @click="resetSdaForm()"
                                    >
                                        <v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.add"
                                            class="ml-3"
                                            color="info"
                                            small
                                            v-if="addMode == true"
                                            @click="addSda()"
                                    >{{ $t('message.create') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.save"
                                            class="ml-3"
                                            color="info"
                                            small
                                            v-if="addMode == false"
                                            @click="saveSda()"
                                    >
                                        {{ $t('message.save') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.cancel"
                                            class="ml-3"
                                            color="error"
                                            small
                                            v-if="addMode == false"
                                            @click="cancelSda(Sda__id)"
                                    >{{ $t('message.cancel') }}
                                    </v-btn>
                                    <v-btn
                                            class="ml-3"
                                            small
                                            v-if="addMode == false"
                                            @click="sendInstructionDialog = true"
                                    >{{ $t('message.sendToSupplier')}}
                                    </v-btn>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <v-dialog
            overlay=false
            persistent
            transition="dialog-bottom-transition"
            v-model="sendInstructionDialog"
            width="800px"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.sendThisShippingInstructionToSupplier') }}</v-card-title>
                <v-card-text class="white">
                    <v-row no-gutters class="pt-3 align-center">
                        <v-col cols="12">
                            <h2>{{ $t('message.selectContacts') }}</h2>
                            <v-simple-table class="appic-table-light specification-tables" dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="grey lighten-3 pa-0 width-1-pct"></th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.name')}}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.email')}}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.position')}}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.receiveNotifications')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="supplierInstructionContacts.length > 0">
                                        <tr v-for="contact in supplierInstructionContacts" v-bind:key="contact.Contact.id">
                                            <td>
                                                <v-checkbox
                                                        :value="contact.Contact.email"
                                                        class="mt-1"
                                                        dense
                                                        hide-details
                                                        v-model="selectedSupplierContacts"
                                                />
                                            </td>
                                            <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.name }}</td>
                                            <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.email }}</td>
                                            <td class="pt-2 pl-0 text-left">{{ contact.Contact.position }}</td>
                                            <td class="pt-2 pl-0 text-center">{{ contact.Contact.otsnotification == 1 ? $t('message.yes') : $t('message.no') }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="5">{{ $t('message.noContactsFound') }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3 white">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="ml-3"
                            color="default"
                            small
                            @click="sendInstructionDialog = false"s
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.send"
                            class="ml-3"
                            color="info"
                            small
                            @click="sendInstructionToSupplier()"
                    >{{ $t('message.send') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
<!--        <PrintSdaBlob-->
<!--                :sda-id="sdaId"-->
<!--                :key="updateKey"-->
<!--                @pdf-uploading="sdaUploading"-->
<!--                @pdf-uploaded="sdaUploaded"-->
<!--                @pdf-not-uploaded="sdaNotUploaded()"-->
<!--                v-if="hasSda"-->
<!--        />-->
        <AlertMissingValues
                :alert_message="dialogs.error_message"
                :dialog.sync="dialogs.error"
                @dialog-closed="dialogClosed"
        />
    </div>
</template>

<script>
import { mapFields, mapMultiRowFields } from "vuex-map-fields";
import { mapActions, mapGetters, mapMutations } from "vuex";
import router from "../../../router";
// import DocumentHistory from "../../../components/Appic/DocumentHistory";
// import SdaAlert from "Components/Appic/SdaAlert";
import {formatDate, isObject, numberFormat} from "Helpers/helpers";
import SdaClauseList from "Components/Appic/SdaClauseList";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
// import {appConstants} from "@/store/modules/appic/constants";
import {api} from "Api";
// import AlertMissingValues from "Components/Appic/AlertMissingValues";
import { v4 as uuidv4 } from 'uuid';
import {nl2br} from "Helpers/helpers";
// import {validationRules} from "@/store/modules/appic/constants";
// import NotificationsLog from "Components/Appic/NotificationsLog";

const AlertMissingValues = () => import("Components/Appic/AlertMissingValues");
const DocumentHistory = () => import("../../../components/Appic/DocumentHistory");
const SdaAlert = () => import("Components/Appic/SdaAlert");
const NotificationsLog = () => import("Components/Appic/NotificationsLog");
// const PrintSdaBlob = () => import("Views/v1/sdas/PrintSdaBlob");
const PrintShippingInstructionButton  = () => import("Components/Appic/Printing/PrintShippingInstructionButton.vue");

export default {
    name: "AddSda",
    components: {
        PrintShippingInstructionButton,
        // PrintSdaBlob,
        NotificationsLog,
        AlertMissingValues,
        DocumentHistory,
        SdaClauseList,
        SdaAlert},
    props: ['sdaId','contractId'],
    title: '',
    data(){
        return {
            defaultDeliveryAddresses: [],
            dialogs: {
                error:  false,
                error_message: ""
            },
            hasSda: false,
            historyKey: '',
            loader: false,
            loading: {
                add: false,
                deliveryAddresses: false,
                reset: false,
                save: false,
                sda: false,
                send: false
            },
            loadingAnotherSda: false,
            panel: [0],
            // rules: {
            //     consigneeBl: v => !!v || this.$t('message.required'),
            //     consigneeCo: v => !!v || this.$t('message.required'),
            //     consigneePc: v => !!v || this.$t('message.required'),
            //     date: v => !!v || this.$t('message.required'),
            //     invoiceTo: v => !!v || this.$t('message.required'),
            //     notifyBl: v => !!v || this.$t('message.required'),
            //     shipper: v => !!v || this.$t('message.required'),
            //     // supplierTerm: v => !!v || this.$t('message.required')
            // },
            sdaDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
            sdaDateMenu: false,
            sdaUrl: null,
            selectedSupplierContacts: [],
            sendInstructionDialog: false,
            supplierContacts: [],
            updateKey: 0,
            validForm: false
        }
    },
    computed: {
        validationRules() {
            return validationRules
        },
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('sda',{
            Sda__alsonotifybl: 'Sda.alsonotifybl',
            Sda__alsonotifybl2: 'Sda.alsonotifybl2',
            Sda__alsonotifyblstatement: 'Sda.alsonotifyblstatement',
            Sda__consigneebltoinvaddress2: 'Sda.consigneebltoinvaddress2',
            Sda__consigneecert2: 'Sda.consigneecert2',
            Sda__consigneephyto2: 'Sda.consigneephyto2',
            Sda__contract_id: 'Sda.contract_id',
            Sda__deliveryaddress_id: "Sda.deliveryaddress_id",
            Sda__doc_deliveryaddress_id: "Sda.doc_deliveryaddress_id",
            Sda__docrequired: 'Sda.docrequired',
            Sda__id: 'Sda.id',
            Sda__invoicingaddress2: 'Sda.invoicingaddress2',
            Sda__longform: 'Sda.longform',
            Sda__othercertstatement: 'Sda.othercertstatement',
            Sda__otherconsignee: 'Sda.otherconsignee',
            Sda__otherinvstatement: 'Sda.otherinvstatement',
            Sda__othernotifybl2: 'Sda.othernotifybl2',
            Sda__othernotifyblstatement: 'Sda.othernotifyblstatement',
            Sda__otherphytostatement: 'Sda.otherphytostatement',
            Sda__othershipper2: 'Sda.othershipper2',
            Sda__othershipperstatement: 'Sda.othershipperstatement',
            Sda__sdadate: 'Sda.sdadate',
            Sda__title: 'Sda.title',
            Sda__Addresses__Customer: 'Sda.Addresses.Customer',
            Sda__Addresses__Supplier: 'Sda.Addresses.Supplier',
            Sda__Contract__customer_id: 'Sda.Contract.customer_id',
            Sda__Contract__incoterm_id: 'Sda.Contract.incoterm_id',
            Sda__Contract__partial_no: 'Sda.Contract.partial_no',
            Sda__Contract__revision_no: 'Sda.Contract.revision_no',
            Sda__Contract__supplier_id: 'Sda.Contract.supplier_id',
            Sda__Contract__title: 'Sda.Contract.title',
            Sda__Contract__version: 'Sda.Contract.version',
            Sda__Supplier__company_id: 'Sda.Supplier.company_id',
        },'statePrefix'),
        ...mapFields('sda',{
            newSdaSaved: 'newSdaSaved',
            newSdaUnsaved: 'newSdaUnsaved',
            updatedSdaUnsaved: 'updatedSdaUnsaved',
            updatedSdaSaved: 'updatedSdaSaved'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('buyer',{
            allBuyers: 'allBuyers'
        }),
        ...mapGetters('contract',{
            allActiveContracts: 'allActiveContracts',
            allContractsWithoutSda: 'allContractsWithoutSda'
        }),
        ...mapGetters('sda',{
            allActiveSdas: 'allActiveSdas'
        }),
        ...mapGetters('supplier',{
            allSuppliers: 'allSuppliers'
        }),
        ...mapGetters([
            'appConstants',
            // 'defaultDeliveryAddresses',
            'validationRules'
        ]),
        addMode: function () {
            return this.$route.matched.some(({name}) => name === 'add_sda')
        },
        computedSdaDateFormatted () {
            return formatDate(this.Sda__sdadate)
        },
        supplierInstructionContacts () {
            return this.supplierContacts.filter(contact => {
                return contact?.Contact?.otsnotification == 1
                // return contact?.Contact?.otsshipping == 1
            })
        },
        updateMode: function () {
            return this.$route.matched.some(({name}) => name === 'update_sda')
        },
    },
    methods: {
        ...mapActions('buyer',{
            getAllActiveBuyers: 'getAllActiveBuyers'
        }),
        ...mapActions('contract',{
            getAllActiveContracts: 'getAllActiveContracts'
        }),
        ...mapActions('sda',{
            assignSdaShipperAddresses: 'assignSdaShipperAddresses',
            cancelSdaById: 'cancelSdaById',
            createSda: 'createSda',
            getAllActiveSdas: 'getAllActiveSdas',
            getSdaToUpdateById: 'getSdaToUpdateById',
            loadContractWithoutSda: 'loadContractWithoutSda',
            resetAllActiveSdas: 'resetAllActiveSdas',
            resetCurrentSda: 'resetCurrentSda',
            sendShippingInstructionToSupplier: 'sendShippingInstructionToSupplier',
            updateSda: 'updateSda'
        }),
        ...mapActions('sdaclause',{
            getClausesBySda: 'getClausesBySda',
            getCurrentClauses: 'getCurrentClauses',
            getDefaultClauses: 'getDefaultClauses',
            reCalculateListIndices: 'reCalculateListIndices',
            resetCurrentClauses: 'resetCurrentClauses',
            resetDefaultClauses: 'resetDefaultClauses'
        }),
        ...mapActions('supplier',{
            getAllSuppliers: 'getAllSuppliers'
        }),
        addSda () {
            if(this.$refs.sdaForm.validate()) {
                this.loading.add = true
                this.createSda()
                    .then((response) => {
                        if (response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.sdaAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.newSdaUnsaved = false
                            this.newSdaSaved = true
                            this.resetCurrentSda().then(() => {
                                this.resetAllActiveSdas().then(() => {
                                    router.push({name: 'update_sda', params: {sdaId: response.sda_id}}).catch(err => {
                                    })
                                })
                            })
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.sdaNotAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.sdaNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.sdaNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
                    .finally(() => {
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.sdaNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
                this.loading.add = false
            }
        },
        assignSdaCustomerAddresses (){
            return new Promise((resolve, reject) => {
                this.Sda__Addresses__Customer = []
                let buyer = this.allBuyers.find(buyer => buyer.Customer.id === this.Sda__Contract__customer_id)
                this.Sda__Addresses__Customer.push({'header': this.appConstants.companyShortName})
                this.Sda__Addresses__Customer.push({
                    value: 'app',
                    text: this.$t('message.useAppTimberAddress'),
                    textSimple: this.$t('message.useAppTimberAddress')
                })
                this.Sda__Addresses__Customer.push({'header': this.$t('message.customerMainAddress')})
                if ( buyer ) {
                    let address =
                        ('<span class="font-weight-bold">' + buyer.Customer.title + '</span>')
                        + (buyer.Company.address1 !== '' && buyer.Company.address1 !== null ? '<span class="font-xs">' + buyer.Company.address1 + '</span>' : '')
                        + (buyer.Company.address2 !== '' && buyer.Company.address2 !== null ? '<span class="font-xs">' + buyer.Company.address2 + '</span>' : '')
                        + (buyer.Company.address3 !== '' && buyer.Company.address3 !== null ? '<span class="font-xs">' + buyer.Company.address3 + '</span>' : '')
                        + (buyer.Company.city !== '' && buyer.Company.city !== null ? '<span class="font-xs">' + buyer.Company.city + ' ' + buyer.Company.postcode + '</span>' : '')
                        + (buyer.Company.state !== '' && buyer.Company.state !== null ? '<span class="font-xs">' + buyer.Company.state + '</span>' : '')
                        + (buyer.Country.name !== '' && buyer.Country.name !== null ? '<span class="font-xs">' + buyer.Country.name + '</span>' : '')

                    let addressSimple =
                        buyer.Customer.title
                        + (buyer.Company.address1 != null && buyer.Company.address1 != '' ? ' ' + buyer.Company.address1 : '')
                        + (buyer.Company.address2 != null && buyer.Company.address2 != '' ? ' ' + buyer.Company.address2 : '')
                        + (buyer.Company.address3 != null && buyer.Company.address3 != '' ? ' ' + buyer.Company.address3 : '')
                        + (buyer.Company.city != null && buyer.Company.city != '' ? ' ' + buyer.Company.city : '')
                        + (buyer.Company.state != null && buyer.Company.state != '' ? ' ' + buyer.Company.state : '')
                        + (buyer.Company.postcode != null && buyer.Company.postcode != '' ? ' ' + buyer.Company.postcode : '')
                        + (buyer.Country.name != null && buyer.Country.name != '' ? ' ' + buyer.Country.name : '')

                    this.Sda__Addresses__Customer.push({
                        value: 'main',
                        text: address,
                        textSimple: addressSimple
                    })
                    this.assignSdaCustomerOtherAddresses(this.Sda__Contract__customer_id).then((addresses)=>{
                        if(addresses.length > 0){
                            this.Sda__Addresses__Customer.push({'header': this.$t('message.customerOtherAddresses')})
                        }
                        addresses.forEach(address => {
                            this.Sda__Addresses__Customer.push({
                                value: address.Deliveryaddress.id.toString(), //important convert integer to string 2021-03-11
                                text: address.Deliveryaddress.address,
                                textSimple: address.Deliveryaddress.address.replace("\n"," ")
                            })
                        })
                        this.Sda__Addresses__Customer.push({
                            value: 'oth',
                            text: this.$t('message.useOtherStatement'),
                            textSimple: this.$t('message.useOtherStatement')
                        })
                        resolve('done')
                    })
                }
            })
        },
        assignSdaCustomerOtherAddresses (customer_id){
            return new Promise((resolve, reject) => {
                let conditions = [
                    {field: 'deliveryaddresses.model', value: 'customer'},
                    {field: 'deliveryaddresses.key_id', value: customer_id}
                ]
                api
                    .get("/addresses", {
                        params: {
                            conditions: conditions,
                        }
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        assignSdaShipperAddresses (){
            return new Promise((resolve, reject) => {
                this.Sda__Addresses__Supplier = []
                let supplier = this.allSuppliers.find(supplier => supplier.Supplier.id === this.Sda__Contract__supplier_id)
                this.Sda__Addresses__Supplier.push({'header': this.$t('message.supplierMainAddress')})
                if ( supplier ) {
                    let address =
                    ('<span class="font-weight-bold">' + supplier.Supplier.title + '</span>')
                    + (supplier.Company.address1 !== null && supplier.Company.address1 !== '' ? '<span class="font-xs">' + supplier.Company.address1 + '</span>' : '')
                    + (supplier.Company.address2 !== null && supplier.Company.address2 !== '' ? '<span class="font-xs">' + supplier.Company.address2 + '</span>' : '')
                    + (supplier.Company.address3 !== null && supplier.Company.address3 !== '' ? '<span class="font-xs">' + supplier.Company.address3 + '</span>' : '')
                    + (supplier.Company.city != null && supplier.Company.city !== '' ? '<span class="font-xs">' + supplier.Company.city + ' ' + supplier.Company.postcode  + '</span>' : '')
                    + (supplier.Company.state != null && supplier.Company.state !== '' ? '<span class="font-xs">' + supplier.Company.state + '</span>' : '')
                    + (supplier.Country.name !== null && supplier.Country.name !== '' ? '<span class="font-xs">' + supplier.Country.name + '</span>' : '')

                    let addressSimple =
                        supplier.Supplier.title
                        + (supplier.Company.address1 != null && supplier.Company.address1 != '' ? ' ' + supplier.Company.address1 : '')
                        + (supplier.Company.address2 != null && supplier.Company.address2 != '' ? ' ' + supplier.Company.address2 : '')
                        + (supplier.Company.address3 != null && supplier.Company.address3 != '' ? ' ' + supplier.Company.address3 : '')
                        + (supplier.Company.city != null && supplier.Company.city != '' ? ' ' + supplier.Company.city : '')
                        + (supplier.Company.state != null && supplier.Company.state != '' ? ' ' + supplier.Company.state : '')
                        + (supplier.Company.postcode != null && supplier.Company.postcode != '' ? ' ' + supplier.Company.postcode : '')
                        + (supplier.Country.name != null && supplier.Country.name != '' ? ' ' + supplier.Country.name : '')

                    this.Sda__Addresses__Supplier.push({
                        value: 'main',
                        text: address,
                        textSimple: addressSimple
                    })
                }
                this.Sda__Addresses__Supplier.push({
                    value: 'oth',
                    text: this.$t('message.useOtherStatement'),
                    textSimple: this.$t('message.useOtherStatement')
                })
                resolve('done')
            })
        },
        async cancelSda (val) {
            if(await this.$root.$confirm(this.$t('message.cancelSda'), this.$t('message.confirmations.continueSdaCancelAction'), {color: 'orange'})){
                this.cancelSdaById(val)
                    .then((response) => {
                        if(response.status == 'success'){
                            this.$toast.success(this.$t('message.successes.sdaDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            router.push({name: 'shipping_documents_listing'}).catch(err => {})
                            this.loading.cancel = false
                        } else {
                            this.$toast.error(this.$t('message.errors.sdaNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(()=>{
                        this.$toast.error(this.$t('message.errors.sdaNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        formatDate,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getDefaultDeliveryAddresses() {
            this.loading.deliveryAddresses = true
            api
                .get("/addresses/document-delivery")
                .then(response => {
                    if(response.data.status === 'success'){
                        this.defaultDeliveryAddresses = response.data.data
                    } else {
                        this.$toast.error(this.$t('message.errors.deliveryAddressesNotLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading.deliveryAddresses = false
                })
                .catch(error => {
                    this.$toast.error(this.$t('message.errors.deliveryAddressesNotLoaded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.deliveryAddresses = false
                })
        },
        getSupplierContacts () {
            let company_id = this.Sda__Supplier__company_id
            return new Promise((resolve, reject) => {
                api
                    .get("/companies/" + company_id + "/contacts")
                    .then(response => {
                        this.supplierContacts = response.data.data
                        resolve('done');
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        resolve('done');
                    })
            })
        },
        loadAnotherActiveSda (val) {
            router.push({name: 'update_sda', params: { sdaId : val}}).catch(err => {})
        },
        loadContract (contract){
            this.loader = true
            let self = this
            setTimeout(function(){
                self.loadContractWithoutSda(contract)
                    .then(() => {
                        self.getCurrentClauses(self.Sda__contract_id)
                            .then(() => {
                                self.reCalculateListIndices({prefix: self.statePrefix, group: 'standard'})
                                self.reCalculateListIndices({prefix: self.statePrefix, group: 'specific'})
                                self.reCalculateListIndices({prefix: self.statePrefix, group: 'other'})
                                self.reCalculateListIndices({prefix: self.statePrefix, group: 'presentation'})
                                self.getDefaultClauses()
                                    .then(()=>{
                                        self.assignSdaShipperAddresses()
                                            .then(() => {
                                                self.assignSdaCustomerAddresses()
                                                    .then(() => {
                                                        self.Sda__consigneebltoinvaddress2 = null
                                                        self.Sda__othernotifybl2 = null
                                                        self.Sda__consigneecert2 = null
                                                        self.Sda__consigneephyto2 = null
                                                        self.Sda__deliveryaddress_id = null
                                                        self.Sda__invoicingaddress2 = 'main'
                                                        self.Sda__othershipper2 = 'main'
                                                        self.Sda__sdadate = new Date().toISOString().substr(0,10)
                                                        self.loader = false
                                                    })
                                            })
                                    })
                        })
                })
            },100)
        },
        loadSdaById (val) {
            this.loader = true
            this.getSdaToUpdateById(val)
                .then(() => {
                    this.$title =  this.$t('message.titles.sda') + ': ' + (this.Sda__Contract__version != null ? [this.Sda__Contract__title, this.Sda__Contract__revision_no , this.Sda__Contract__partial_no].filter(Boolean).join('-') : this.Sda__Contract__title)
                    this.getSupplierContacts()
                        .then(()=>{
                            this.supplierInstructionContacts.forEach(c => {
                                this.selectedSupplierContacts.push(c.Contact.email)
                            })
                        })
                    this.getClausesBySda(val)
                        .then(()=>{
                            this.reCalculateListIndices({prefix: this.statePrefix, group: 'standard'})
                            this.reCalculateListIndices({prefix: this.statePrefix, group: 'specific'})
                            this.reCalculateListIndices({prefix: this.statePrefix, group: 'other'})
                            this.reCalculateListIndices({prefix: this.statePrefix, group: 'presentation'})
                            this.getDefaultClauses()
                                .then(()=> {
                                    this.assignSdaShipperAddresses()
                                        .then(() => {
                                            this.assignSdaCustomerAddresses()
                                                .then(() => {
                                                    this.loader = false
                                                    this.updatedSdaUnsaved = false
                                                })
                                                .catch(()=>{
                                                    this.loader = false
                                                    this.updatedSdaUnsaved = false
                                                })
                                                .then(()=>{
                                                    this.loader = false
                                                    this.updatedSdaUnsaved = false
                                                })
                                        })
                                })
                        })
                })
                .catch(() => {
                    this.updatedSdaUnsaved = false
                    this.loader = false;
                    router
                        .push({name: 'not_found_main', params: {resource: 'SI'}})
                        .catch(() => {
                            console.log('error')
                        })
                })
        },
        nl2br,
        removeBreak (str) {
            let newStr = str.replace(/<br\s*\/?>/gi," ")
            return newStr
        },
        async resetSdaForm() {
            if (await this.$root.$confirm(this.$t('message.resetSdaForm'), this.$t('message.confirmations.continueSdaFormReset'), {color: 'orange'})) {
                this.loading.reset = true
                this.resetCurrentSda()
                    .then(() => {
                        this.resetCurrentClauses()
                        this.resetDefaultClauses()
                    })
                    .catch(()=>{
                        this.loading.reset = false
                    })
                    .then(()=>{
                        this.loading.reset = false
                    })
            }
        },
        saveSda () {
            if(this.$refs.sdaForm.validate()) {
                this.loading.save = true
                this.updateSda()
                    .then((response) => {
                        if (response.status == 'success') {
                            //reload the clauses
                            this.getClausesBySda(this.sdaId)
                                .then(() => {
                                    this.reCalculateListIndices({prefix: this.statePrefix, group: 'standard'})
                                    this.reCalculateListIndices({prefix: this.statePrefix, group: 'specific'})
                                    this.reCalculateListIndices({prefix: this.statePrefix, group: 'other'})
                                    this.reCalculateListIndices({prefix: this.statePrefix, group: 'presentation'})
                                })
                            this.$toast.success(this.$t('message.successes.sdaUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.updatedSdaUnsaved = false
                        } else {
                            if(isObject(response.message)){
                                //extract validation errors
                                const errors = Object.values(response.message)
                                errors.forEach(errorObj => {
                                    if (Array.isArray(errorObj)) {
                                        errorObj.forEach(error => {
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                    } else {
                                        this.$toast.error(this.$t('message.errors.sdaNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.sdaNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.save = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.sdaNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.sdaNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
                this.loading.save = false
            }
        },
        sdaNotUploaded() {
            this.loading.send = false
        },
        sdaUploaded(url) {
            this.sdaUrl = url
            this.loading.sda = false
            this.loading.send = false
        },
        sdaUploading() {
            this.loading.send = true
        },
        sendInstructionToSupplier () {
            if(this.selectedSupplierContacts.length === 0){
                this.dialogs.error_message = this.$t('message.errors.pleaseSelectOneContact');
                this.dialogs.error = true
            } else {
                this.loading.send = true
                let payload = {
                    contacts: this.selectedSupplierContacts,
                    sdaId: this.Sda__id,
                }
                this.sendShippingInstructionToSupplier(payload)
                    .then((status)=>{
                        if (status === 'done') {
                            this.$toast.success(this.$t('message.successes.shippingInstructionSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                        } else {
                            this.$toast.error(this.$t('message.errors.shippingInstructionNotSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.shippingInstructionNotSent'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .finally(() => {
                        this.loading.send = false
                        this.selectedSupplierContacts = []
                        this.sendInstructionDialog = false
                    })
            }
        },
        updateHistoryKey () {
            this.historyKey = Math.random() * (1000 - 1) + 1
        },
        // viewPdf () {
        //     let document = encodeURIComponent((this.Sda__Contract__version != null ? [this.Sda__Contract__title, this.Sda__Contract__revision_no , this.Sda__Contract__partial_no].filter(Boolean).join('-') : this.Sda__Contract__title) + '_SI')
        //     let user = JSON.parse(localStorage.getItem('user'))
        //     let tab = window.open(
        //         process.env.VUE_APP_PDF_SERVER
        //         + '/api/render/'
        //         + document
        //         + '?url='
        //         + process.env.VUE_APP_BASE_URL
        //         + '/v1/sdas/print/'
        //         + this.sdaId
        //         + '/'
        //         + uuidv4()
        //         + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
        //         , "_blank");
        //     tab.name = 'tab_' + Math.floor(Math.random() * 10000)
        //     this.openedTabs.push(tab.name)
        // }
    },
    watch: {
        Sda__alsonotifybl: function(val){
            if (val != 1) {
                this.Sda__alsonotifybl2 = null
                this.Sda__alsonotifyblstatement = null
            }
        },
        // sendInstructionDialog(value) {
        //     this.hasSda = value
        // }
        sendInstructionDialog(value) {
            if(value) {
                this.loading.send = true
                api
                    .put('/sdas/' + this.sdaId + '/pdf')
                    .then((response) => {
                        if (response.data.status == 'success') {
                            this.loading.send = false
                        } else {
                            this.$toast.error(this.$t('message.errors.pdfError'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                })
                            this.loading.send = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                        this.loading.send = false
                    })
            }
        }
    },
    created () {
        if(this.allContractsWithoutSda.length === 0) this.getAllActiveContracts()
        if(this.allSuppliers.length === 0) this.getAllSuppliers()
        if(this.allBuyers.length === 0) this.getAllActiveBuyers()

        // if(this.allActiveSdas.length == 0) this.getAllActiveSdas()

        this.getAllActiveSdas()
        this.getDefaultDeliveryAddresses()

        if(this.$route.matched.some(({name}) => name === 'update_sda')) {
            this.loadSdaById(this.sdaId)
        } else if(this.$route.matched.some(({name}) => name === 'add_sda')){
            this.newSdaUnsaved = true;
        }
    },
    mounted () {
        this.$store.watch(
            function(state) {
                return state.appic.sda.update.Sda
            },
            () => {
                this.updatedSdaUnsaved = true
            },
            { deep: true }
        )
        this.$store.watch(
            function(state) {
                return state.appic.sda.current.Sda
            },
            () => {
                this.newSdaUnsaved = true
            },
            { deep: true }
        )
    }
}
</script>

<style>
    .text-area-border {
        border-color: darkgrey;
        border-radius: 0.3em;
        border-style: dashed;
        padding: 0.5em;
    }
    .font-xs {
        font-size: 0.9em !important;
    }
    .v-list--dense .v-subheader {
        height: 25px;
    }
</style>